// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flota-js": () => import("./../../../src/pages/flota.js" /* webpackChunkName: "component---src-pages-flota-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("./../../../src/pages/kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-uslugi-transport-krajowy-js": () => import("./../../../src/pages/uslugi/transport-krajowy.js" /* webpackChunkName: "component---src-pages-uslugi-transport-krajowy-js" */),
  "component---src-pages-uslugi-transport-ladunkow-neutralnych-js": () => import("./../../../src/pages/uslugi/transport-ladunkow-neutralnych.js" /* webpackChunkName: "component---src-pages-uslugi-transport-ladunkow-neutralnych-js" */),
  "component---src-pages-uslugi-transport-ladunkow-sypkich-js": () => import("./../../../src/pages/uslugi/transport-ladunkow-sypkich.js" /* webpackChunkName: "component---src-pages-uslugi-transport-ladunkow-sypkich-js" */),
  "component---src-pages-uslugi-transport-materialow-niebezpiecznych-js": () => import("./../../../src/pages/uslugi/transport-materialow-niebezpiecznych.js" /* webpackChunkName: "component---src-pages-uslugi-transport-materialow-niebezpiecznych-js" */),
  "component---src-pages-uslugi-transport-miedzynarodowy-js": () => import("./../../../src/pages/uslugi/transport-miedzynarodowy.js" /* webpackChunkName: "component---src-pages-uslugi-transport-miedzynarodowy-js" */),
  "component---src-pages-uslugi-transport-ponadgabarytowy-js": () => import("./../../../src/pages/uslugi/transport-ponadgabarytowy.js" /* webpackChunkName: "component---src-pages-uslugi-transport-ponadgabarytowy-js" */),
  "component---src-pages-uslugi-transport-w-temperaturze-kontrolowanej-js": () => import("./../../../src/pages/uslugi/transport-w-temperaturze-kontrolowanej.js" /* webpackChunkName: "component---src-pages-uslugi-transport-w-temperaturze-kontrolowanej-js" */),
  "component---src-templates-career-post-js": () => import("./../../../src/templates/career-post.js" /* webpackChunkName: "component---src-templates-career-post-js" */)
}

